<div class="grid p-0 m-0" [style.width.%]="100">
    <div class="col-12 p-0" [style.width.%]="100">
        <p-menubar [model]="menuList" >
            <ng-template pTemplate="start">
                <img src="assets/designstyle-night2.png" height="32" class="p-mr-2">
            </ng-template>
            <ng-template pTemplate="end">
                <input type="text" pInputText placeholder="Buscar">
            </ng-template>
        </p-menubar>
    </div>
</div>

<div [ngStyle]="{height: 'calc(100% - 66px)'}" [style.width.%]="100" style="padding: 10px;">
    <router-outlet></router-outlet>
</div>

<p-toast></p-toast>
