import { Component } from '@angular/core';
import {
    columnGridOptions,
    flexDirectionGridOptions,
    justifyContentOptions,
    rowGridOptions,
    alignItemsOptions,
    gapOptions,
    containerColumnOptions,
    containerRowOptions,
    containerGridIdOptions
} from '../../services/form-data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

interface DropdownOptions {
    name: string;
    code: string;
};

interface Grid {
    numberOfGrid: number;
    gridColumn: string;
    gridRow: string;
    FlexDirection: string;
    FlexJustifyContent: string;
    FlexAlignItems: string;
    FlexGap: string;
};

interface Container {
    id: string | null;
    name: string;
    justifyContentValue: string;
    gapValue: string;
    gridColumns: string;
    gridRows: string;
    gridList: Grid[]
}

@Component({
    selector: 'app-create-container',
    templateUrl: './create-container.component.html',
    styleUrls: ['./create-container.component.css'],
    providers: [MessageService]
})
export class CreateContainerComponent {
    urlRenderManager = environment.urlRenderManager;
    idContainer: number | null = null;
    containerName: string = '';
    containerID: number | null = null;
    existContainerID: boolean = false;

    containerColumnOptions: DropdownOptions[] | undefined;
    selectedContainerColumn: DropdownOptions | undefined;

    containerRowOptions: DropdownOptions[] | undefined;
    selectedContainerRow: DropdownOptions | undefined;

    justifyContainerOptions: DropdownOptions[] | undefined;
    selectedJustifyContainer: DropdownOptions | undefined;

    gapContainerOptions: DropdownOptions[] | undefined;
    selectedGapContainer: DropdownOptions | undefined;
    
    containerGridIdOptions: DropdownOptions[] | undefined;
    selectedContainerGridId: DropdownOptions | undefined;

    columnGridOptions: DropdownOptions[] | undefined;
    selectedcolumnGrid: DropdownOptions | undefined;

    rowGridOptions: DropdownOptions[] | undefined;
    selectedrowGrid: DropdownOptions | undefined;

    flexDirectionGridOptions: DropdownOptions[] | undefined;
    selectedflexDirectionGrid: DropdownOptions | undefined;

    justifyContentGridOptions: DropdownOptions[] | undefined;
    selectedjustifyContentGrid: DropdownOptions | undefined;

    alignItemsGridOptions: DropdownOptions[] | undefined;
    selectedAlignItemsGrid: DropdownOptions | undefined;

    gapGridOptions: DropdownOptions[] | undefined;
    selectedgapGrid: DropdownOptions | undefined;

    grids: Grid[] | undefined = [];
    selectedGrid: Grid | undefined;

    idForUpdateGrid: number | null = null;

    containerSubheader: string;
    containerHeader: string;
    constructor(private httpClient: HttpClient, private messageService: MessageService){}

    ngOnInit() {
        // this.containerSubheader = 'CONTAINER ID: 5026';
        this.containerSubheader = '';
        this.containerHeader = 'Create New Container';

        this.justifyContainerOptions = justifyContentOptions;
        this.gapContainerOptions = gapOptions;
        this.containerColumnOptions = containerColumnOptions;
        this.containerRowOptions = containerRowOptions;
        
        this.containerGridIdOptions = containerGridIdOptions;
        this.columnGridOptions = columnGridOptions;
        this.rowGridOptions = rowGridOptions;
        this.flexDirectionGridOptions = flexDirectionGridOptions;
        this.justifyContentGridOptions = justifyContentOptions;
        this.alignItemsGridOptions = alignItemsOptions;
        this.gapGridOptions = gapOptions;
    }

    saveContainer(){
        const body: Container = {
            id: null,
            name: this.containerName,
            justifyContentValue: this.selectedJustifyContainer.name,
            gapValue: this.selectedGapContainer.name,
            gridColumns: this.selectedContainerColumn.name,
            gridRows: this.selectedContainerRow.name,
            gridList: this.grids
        };

        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.httpClient.post(`${this.urlRenderManager}/render-manager/v1/container`, body, {headers: headers}).subscribe(
            (res:any)=>{
                this.idContainer = parseInt(res.container);
                this.containerID = +res.container;
                this.existContainerID = true;
                this.containerSubheader = `CONTAINER ID: ${this.idContainer}`
                this.containerHeader = `Update Container`
                this.showSuccess();
                console.log("res", res);
            },
            (error)=>{
                console.log("error", error);
            },
        );
    };

    updateContainer(){
        console.log("update")
        const bodyPatch = {
            id: this.containerID,
            name: this.containerName,
            justifyContentValue: this.selectedJustifyContainer.name,
            gapValue: this.selectedGapContainer.name,
            columns: +this.selectedContainerColumn.name,
            rows: +this.selectedContainerRow.name,
            gridList: this.grids
        };

        this.httpClient.patch(`${this.urlRenderManager}/render-manager/v1/container`, bodyPatch).subscribe(
            (res) =>{
                console.log("bodyPatch: ", bodyPatch)
                this.showMessage('Contenedor Actualizado Correctamente');
            },
            (error) =>{

            }
        );
    };

    searchContainer(){
        this.httpClient.get(`${this.urlRenderManager}/render-manager/v1/container/${this.containerID}`).subscribe(
            (res:any) => {
                this.existContainerID = true;
                this.containerName = res.container.name;
                this.selectedGapContainer = this.gapContainerOptions.find(item => item.code === res.container.gapValue);
                this.selectedContainerColumn = {name: String(res.container.columns.length), code: String(res.container.columns.length)};
                this.selectedContainerRow = {name: String(res.container.rows.length), code: String(res.container.rows.length)};
                this.grids = res.container.gridList;

                console.log("res", res.container.id);
            },
            (error) => {
                console.log("error searchContainer: ", error)
            }
        );
    };

    addGrid(){
        this.grids.push({
            numberOfGrid: parseInt(this.selectedContainerGridId.code),
            gridColumn: this.selectedcolumnGrid.code,
            gridRow: this.selectedrowGrid.code,
            FlexDirection:this.selectedflexDirectionGrid.code,
            FlexJustifyContent: this.selectedjustifyContentGrid.code,
            FlexAlignItems: this.selectedAlignItemsGrid.code,
            FlexGap: this.selectedgapGrid.code
        })
    }

    removeGrid(numberOfGrid: number){
        const indexGrid = this.grids.findIndex(obj => obj.numberOfGrid === numberOfGrid)

        this.grids.splice(indexGrid, 1);
    }

    selectGrid = (numberOfGrid: number) => {
        this.idForUpdateGrid = numberOfGrid;
        
        const [gridSelected] = this.grids.filter((grid)=> grid.numberOfGrid === numberOfGrid)
        
        const properties = [
            {property: 'numberOfGrid', name:'selectedContainerGridId'},
            {property: 'gridColumn', name:'selectedcolumnGrid' },
            {property: 'gridRow', name:'selectedrowGrid' },
            {property: 'FlexDirection', name:'selectedflexDirectionGrid' },
            {property: 'FlexJustifyContent', name:'selectedjustifyContentGrid' },
            {property: 'FlexAlignItems', name:'selectedAlignItemsGrid' },
            {property: 'FlexGap', name:'selectedgapGrid'}
        ];

        properties.forEach((prop)=>{
            const dropDownvalue = prop.property ==='numberOfGrid' ? String(gridSelected[prop.property]) : gridSelected[prop.property]; 

            this[`${prop.name}`] = {name: dropDownvalue, code: dropDownvalue};
        });
    }

    cleanGrid(){
        const properties = [
            {property: 'numberOfGrid', name:'selectedContainerGridId'},
            {property: 'gridColumn', name:'selectedcolumnGrid' },
            {property: 'gridRow', name:'selectedrowGrid' },
            {property: 'FlexDirection', name:'selectedflexDirectionGrid' },
            {property: 'FlexJustifyContent', name:'selectedjustifyContentGrid' },
            {property: 'FlexAlignItems', name:'selectedAlignItemsGrid' },
            {property: 'FlexGap', name:'selectedgapGrid'}
        ];

        properties.forEach((prop)=> this[`${prop.name}`] = undefined );
        this.idForUpdateGrid = null;
    }

    updateGrid(){
        const properties = [
            {property: 'numberOfGrid', name:'selectedContainerGridId'},
            {property: 'gridColumn', name:'selectedcolumnGrid' },
            {property: 'gridRow', name:'selectedrowGrid' },
            {property: 'FlexDirection', name:'selectedflexDirectionGrid' },
            {property: 'FlexJustifyContent', name:'selectedjustifyContentGrid' },
            {property: 'FlexAlignItems', name:'selectedAlignItemsGrid' },
            {property: 'FlexGap', name:'selectedgapGrid'},
        ];
        
        this.grids.forEach((grid)=>{
            if (grid.numberOfGrid === this.idForUpdateGrid) {
                grid.numberOfGrid = parseInt(this.selectedContainerGridId.name);
                grid.gridColumn = this.selectedcolumnGrid.name;
                grid.gridRow = this.selectedrowGrid.name;
                grid.FlexDirection = this.selectedflexDirectionGrid.name;
                grid.FlexJustifyContent = this.selectedjustifyContentGrid.name;
                grid.FlexAlignItems = this.selectedAlignItemsGrid.name;
                grid.FlexGap = this.selectedgapGrid.name;
            }
        });

        properties.forEach((prop) => this[`${prop.name}`] = undefined);
        this.idForUpdateGrid = null;
    }

    showSuccess() {
        this.messageService.add({ severity: 'success', summary: 'OK', detail: `Contenedor creado exitosamente ID: ${this.idContainer}` });
    }

    showMessage(msg: string) {
        console.log("prueba 1")
        this.messageService.add({ severity: 'success', summary: 'Success', detail: msg });
    }
}
