<div [style.height.%]="topSpacing"></div>

<div class="flex w-full justify-content-center flex-wrap">
    <p-card [style.width]="widthLogin">
        <ng-template pTemplate="header">
            <img alt="Card" src="assets/usercard.png">
        </ng-template>

        <form class="grid w-full justify-content-center" (ngSubmit)='login(loginForm)' #loginForm="ngForm">
            <!-- <div class="p-text-center p-login-title p-my-3">INICIAR SESIÓN</div> -->
            <div class="field col-12 md:col-11">
                <div class="text-center p-login-title">INICIAR SESION</div>
            </div>    
            <div class="field col-12 md:col-11">
                <label class="p-login-fields" >Usuario</label>
                <div class="p-inputgroup mt-2">
                    <input pInputText type="text" name="usuario" [(ngModel)]="usuario.user" placeholder="Ingrese usuario">         
                    <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                </div>
            </div>
            <div class="field col-12 md:col-11">
                <label class="p-login-fields">Contraseña</label>
                <div class="p-inputgroup mt-2">
                    <input name="password" [(ngModel)]="usuario.password" type="password" pInputText placeholder="Ingrese contraseña">         
                    <span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
                </div>
            </div>
            <div class="field col-12 md:col-11">
                <div class="flex align-content-center">
                    <p-checkbox inputId="binary" binary="true"></p-checkbox>
                    <label class="p-login-remember" for="binary">Recordarme</label>
                </div>
            </div>
            <div class="field col-12 md:col-11">
                <p-button styleClass="w-full" label="Log In" type="submit" [icon]="icoLoading" [ngClass]="{'button-disabled': loading}"></p-button>
            </div>
            <div class="text-center p-login-recover mt-4 mb-2">
                <i class="pi pi-lock p-mr-2"></i><a style="color: inherit; text-decoration: inherit;" href="javacript:void(0)">Olvidaste tu contraseña?</a>
            </div>
        </form>
    </p-card>
    
</div>
<div class="flex justify-content-center flex-wrap">
    <div class="p-login-made mt-6">No tiene una cuenta? <a href="" style="color:#556EE6; text-decoration: inherit;">Registrese</a></div>
</div>
<div class="flex justify-content-center flex-wrap">
    <div class="p-login-made mt-3">© 2021 WebInk. Todos los derechos reservados</div>
</div>

<p-toast position="bottom-center"></p-toast>
