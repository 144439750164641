
<br>
<p-card [header]="containerHeader" [subheader]="containerSubheader" [style]="{ width: '750px', margin: '0 auto', 'max-width': '100%'}">
    <p-divider align="left">
        <div class="inline-flex align-items-center">
            <i class="pi pi-desktop mr-2"></i>
            <b>Container Setting</b>
        </div>
    </p-divider>
    <div class="formgrid grid p-fluid">
        <div class="field col-12 md:col-1">
            <label for="column1">ID</label>
            <input [disabled]="existContainerID" pInputText id="id_container" placeholder="" aria-describedby="username-help" [(ngModel)]="containerID" />
        </div>
        <div class="field col-12 md:col-5">
            <label htmlFor="username">container name</label>
            <input pInputText id="username" placeholder="Enter container name" aria-describedby="username-help" [(ngModel)]="containerName" />
        </div>
        <div class="field col-12 md:col-2">
            <label for="column1">justify-content</label>
            <p-dropdown id="column1" [(ngModel)]="selectedJustifyContainer" [options]="justifyContainerOptions" optionLabel="name"></p-dropdown>
        </div>
        <div class="field col-12 md:col-2">
            <label for="column2">gap&nbsp;</label>
            <p-dropdown id="column2" [(ngModel)]="selectedGapContainer" [options]="gapContainerOptions" optionLabel="name"></p-dropdown>
        </div>
        <div class="field col-12 md:col-1">
            <label for="column3">columns</label>
            <p-dropdown id="column3" [(ngModel)]="selectedContainerColumn" [options]="containerColumnOptions" optionLabel="name"></p-dropdown>
        </div>
        <div class="field col-12 md:col-1">
            <label for="column4">rows&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <p-dropdown id="column4" [(ngModel)]="selectedContainerRow" [options]="containerRowOptions" optionLabel="name"></p-dropdown>
        </div>
    </div>
    <p-divider align="left">
        <div class="inline-flex align-items-center">
            <i class="pi pi-th-large mr-2"></i>
            <b>Add Grid</b>
        </div>
    </p-divider>
    <div class="formgrid grid p-fluid justify-content-center align-items-center">
        <div class="field col-12 md:col-1">
            <label for="columnID">ID&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <p-dropdown [disabled]="idForUpdateGrid !== null" id="columnID" [(ngModel)]="selectedContainerGridId" [options]="containerGridIdOptions" optionLabel="name"></p-dropdown>
        </div>
        <div class="field col-12 md:col-2">
            <label for="column1">columns</label>
            <p-dropdown id="column1" [(ngModel)]="selectedcolumnGrid" [options]="columnGridOptions" optionLabel="name"></p-dropdown>
        </div>
        <div class="field col-12 md:col-1">
            <label for="column1">rows&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <p-dropdown id="column1" [(ngModel)]="selectedrowGrid" [options]="rowGridOptions" optionLabel="name"></p-dropdown>
        </div>
        <div class="field col-12 md:col-2">
            <label for="column2">flex-direction</label>
            <p-dropdown id="column2" [(ngModel)]="selectedflexDirectionGrid" [options]="flexDirectionGridOptions" optionLabel="name"></p-dropdown>
        </div>
        <div class="field col-12 md:col-2">
            <label for="column3">justify-content</label>
            <p-dropdown id="column3" [(ngModel)]="selectedjustifyContentGrid" [options]="justifyContentGridOptions" optionLabel="name"></p-dropdown>
        </div>
        <div class="field col-12 md:col-2">
            <label for="column4">align-items</label>
            <p-dropdown id="column4" [(ngModel)]="selectedAlignItemsGrid" [options]="alignItemsGridOptions" optionLabel="name"></p-dropdown>
        </div>
        <div class="field col-12 md:col-1">
            <label for="column4">gap</label>
            <p-dropdown id="column4" [(ngModel)]="selectedgapGrid" [options]="gapGridOptions" optionLabel="name"></p-dropdown>
        </div>
        <div *ngIf="!idForUpdateGrid" class="field col-4 md:col-1">
            <label for="column6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <p-button for="column6" (click)="addGrid()" label="" icon="pi pi-plus"></p-button>
        </div>
        <div *ngIf="idForUpdateGrid" class="field col-4 md:col-1">
            <label for="column6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <p-button for="column6" (click)="cleanGrid()" label="" icon="pi pi-times" styleClass="p-button-danger"></p-button>
        </div>
        <div *ngIf="!idForUpdateGrid && !existContainerID" class="field col-4 md:col-2">
            <label for="column7">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <p-button for="column7" (click)="saveContainer()" label="Save" icon="pi pi-save"></p-button>
        </div>
        <div *ngIf="existContainerID" class="field col-4 md:col-2">
            <label for="column7">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <p-button for="column7" (click)="updateContainer()" label="Update" icon="pi pi-save"></p-button>
        </div>
        <div *ngIf="idForUpdateGrid" class="field col-4 md:col-2">
            <label for="column7">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <p-button for="column7" label="Update Grid" (click)="updateGrid()" styleClass="p-button-danger" icon="pi pi-table"></p-button>
        </div>
        <div *ngIf="!existContainerID" class="field col-1 md:col-1">
            <label for="column9">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <p-button for="column9" label="" (click)="searchContainer()" icon="pi pi-refresh"></p-button>
        </div>
    </div>
</p-card>

<br>

<p-card subheader="CONTAINER GRID LIST"  [style]="{ width: '750px', margin: '0 auto', 'max-width': '100%'}">
    <p-table 
        [value]="grids" 
        [tableStyle]="{ 'width': '660px', margin:'0 auto' }" 
        scrollHeight="450px"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>ID</th>
                <th>columns</th>
                <th>rows</th>
                <th>flex-direction</th>
                <th>justify-content</th>
                <th>align-items</th>
                <th>gap</th>
                <th style="width: 5rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-grid>
            <tr>
                <td>{{ grid.numberOfGrid }}</td>
                <td>{{ grid.gridColumn }}</td>
                <td>{{ grid.gridRow }}</td>
                <td>{{ grid.FlexDirection }}</td>
                <td>{{ grid.FlexJustifyContent }}</td>
                <td>{{ grid.FlexAlignItems }}</td>
                <td>{{ grid.FlexGap }}</td>
                <td>
                    <div class="flex gap-2">
                        <button type="button" pButton pRipple (click)="removeGrid(grid.numberOfGrid)" icon="pi pi-trash"></button>
                        <button type="button" pButton pRipple (click)="selectGrid(grid.numberOfGrid)" icon="pi pi-pencil"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template pTemplate="footer">
        <div class="grid justify-content-center">
            <p-button pRipple label="Add Pages" (click)="showPrueba()" icon="pi pi-chevron-right" ></p-button>
        </div>
    </ng-template>
</p-card>
<!-- <p-toast position="top-left" key="confirm1"></p-toast> -->
<p-toast></p-toast>
