<div class="container">
    <div class="grid m-0 justify-content-center">
        <div class="field col-12 lg:col-12 p-0">
            <p-toolbar>
                <div class="p-toolbar-group-start">
                    <i class="p-toolbar-separator pi pi-home ico" style="vertical-align: middle"></i>
                    <i class="p-toolbar-separator pi pi-angle-right ico" style="vertical-align: middle"></i>
                    <div class="p-text-bold">CONTENEDOR</div>
                    <i class="p-toolbar-separator pi pi-angle-right ico" style="vertical-align: middle"></i>
                    <div class="p-text-bold">{{contenName}}</div>
                </div>
                
                <div class="p-toolbar-group-end">
                    <p-button icon="pi pi-save" class="mr-2" (click)="guardar()" styleClass="p-button-info"></p-button>
                    <p-splitButton label="Options" icon="pi pi-check" [model]="options" styleClass="p-button-info p-mr-2"></p-splitButton>
                </div>
            </p-toolbar>
        </div>
        <div class="field col-12 lg:col-2 p-0 options">
            <!-- <p-panel header="Paginas"  [toggleable]="true">
                <p-panelMenu [model]="items" [style]="{'width':'100%'}"></p-panelMenu>
            </p-panel> -->
            <p-panel header="Paginas"  [toggleable]="true">
                <!-- <p-panelMenu [model]="paginas" [style]="{'width':'100%'}"></p-panelMenu> -->
                <p-listbox [options]="optionsNew" [(ngModel)]="optionsNewSelected" (click)="setPaginas(optionsNewSelected)" optionLabel="no_compag" [style]="{'width':'100%'}">
                    <ng-template let-pagina pTemplate="item">
                            <i class="pi pi-file p-mr-1" style="vertical-align: middle"></i>{{pagina.no_compag}}
                    </ng-template>
                </p-listbox>                
            </p-panel>
        </div>

        <div class="field col-12 lg:col-10 p-0 main-content">
            <p-tabView [(activeIndex)]="indexTab">
                <p-tabPanel rightIcon="pi-bookmark">
                    <ng-template pTemplate = "header" >
                        <i *ngIf="existsValpag"  class="pi pi-copy"></i>
                        <i *ngIf="!existsValpag" class="pi pi-times-circle" [style.color]="'red'"></i>
                        <span class="pl-2">Script Carga</span>
                    </ng-template>
                    <div #editor1 (keydown)="onKeydown($event)" [ngClass]="[fullScreen ? 'fullscreen' : 'normal']" ></div>
                </p-tabPanel>
                <p-tabPanel header="Header II">
                    <ng-template pTemplate = "header">
                        <i *ngIf="existsPropag"  class="pi pi-copy"></i>
                        <i *ngIf="!existsPropag" class="pi pi-times-circle" [style.color]="'red'"></i>
                        <span class="pl-2">Script Proceso</span>
                    </ng-template>
                    <div #editor2 (keydown)="onKeydown($event)" [ngClass]="[fullScreen ? 'fullscreen' : 'normal']" ></div>
                </p-tabPanel>
                <p-tabPanel header="Header III">
                    <ng-template pTemplate = "header">
                        <i *ngIf="existsPropos"  class="pi pi-copy"></i>
                        <i *ngIf="!existsPropos" class="pi pi-times-circle" [style.color]="'red'"></i>
                        <span class="pl-2">Script Otros</span>
                    </ng-template>
                    <div #editor3 (keydown)="onKeydown($event)" [ngClass]="[fullScreen ? 'fullscreen' : 'normal']" ></div>
                </p-tabPanel>
            </p-tabView>
        </div>

    </div>
</div>
