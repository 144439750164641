
    <!-- <div class="flex justify-content-center flex-wrap w-full"> -->

        <p-card header="Busqueda de Contenedor" [style]="{'width': '50rem', margin: '0 auto', 'max-width':'100%'}">
            <div class="formgrid grid w-full justify-content-center align-items-center">
                <div class="field col-12 md:col-5">
                    <p-dropdown styleClass="w-full" inputId="state" [options]="opciones" [(ngModel)]="selectedtipoBusq" placeholder="Seleccione" optionLabel="no_compag"></p-dropdown>
                </div>
                <div class="field col-12 md:col-5">
                    <input type="text" pInputText id="datoBusqueda"  style="width: 100%;"  [(ngModel)]="valorBusqueda" (keyup.enter)="searchConten()" placeholder="Ingrese busqueda"  > 
                </div>
                <div class="field col-4 md:col-2">
                    <p-button styleClass="w-full" (click)="searchConten()" type="button" label="Buscar"></p-button>
                </div>
            </div>
        </p-card>

    <!-- </div> -->
    <br>
    <p-card *ngIf="contenedores.length > 0" [style]="{'width': '50rem', margin: '0 auto', 'max-width':'100%'}">
    <!-- <div *ngIf="contenedores.length > 0" class="formgrid grid justify-content-center" [style]="{'max-width':'100%'}"> -->
        <p-table class="p-shadow-2" [style.width.rem]="70" [value]="contenedores" selectionMode="single" [(selection)]="contenedorSeleccionado" dataKey="numero"
            (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th >Nro.</th>
                    <th pSortableColumn="nombre">Contenedor<p-sortIcon field="nombre"></p-sortIcon></th>
                    <th pSortableColumn="numero">Numero Contenedor<p-sortIcon field="numero"></p-sortIcon></th>
                    <th pSortableColumn="paginas_cant">Cant. Paginas<p-sortIcon field="paginas_cant"></p-sortIcon></th>
                    <th pSortableColumn="usuario">Usuario<p-sortIcon field="usuario"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contenedor let-rowIndex="rowIndex">
                <tr [pSelectableRow]="contenedor">
                    <td>{{rowIndex + 1}}</td>
                    <td>{{contenedor.nombre}}</td>
                    <td>{{contenedor.numero}}</td>
                    <td>{{contenedor.paginas_cant}}</td>
                    <td>{{contenedor.usuario}}</td>
                </tr>
            </ng-template>
        </p-table>
    <!-- </div> -->
    </p-card>
